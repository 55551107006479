const formsSubmitting = new Map<HTMLFormElement, boolean>();
document.addEventListener("DOMContentLoaded", event => {
    document.body.addEventListener("submit", event => {
        const form = event.target as HTMLFormElement;
        if (form instanceof HTMLFormElement && form.classList.contains("prevent-multiple-submit")) {
            formsSubmitting.set(form, true)
            form.inert = true
        }
    })
})
window.addEventListener('pageshow', () => {
    formsSubmitting.forEach((value, form) => {
        form.inert = false;
    })
})
